const L3 = {
    key: 'L3',
    name: 'Lecture 3: Rethinking Competition',
    questions: [
        {
            question: "What is the core idea behind the David & Goliath strategy?",
            options: [
                "Using size and strength to dominate",
                "Using speed, agility, and creativity to overcome larger competitors",
                "Competing on equal resources with large firms",
                "Offering lower prices than competitors"
            ],
            answer: "Using speed, agility, and creativity to overcome larger competitors"
        },
        {
            question: "Which of the following is NOT a driver of collaborative competition?",
            options: [
                "Technology",
                "Social and cultural issues",
                "Market dominance",
                "Political and legal issues"
            ],
            answer: "Market dominance"
        },
        {
            question: "What is an example of collaborative competition?",
            options: [
                "Banks sharing ATM networks",
                "A company acquiring all its competitors",
                "Lowering prices to beat competitors",
                "Exclusive supplier agreements"
            ],
            answer: "Banks sharing ATM networks"
        },
        {
            question: "Why is it harder to sustain a competitive advantage in hyper-competition?",
            options: [
                "Products have shorter life cycles",
                "Competitors don’t innovate",
                "Markets grow more slowly",
                "Intangible resources are easier to imitate"
            ],
            answer: "Products have shorter life cycles"
        },
        {
            question: "How does collaborative competition help businesses?",
            options: [
                "By eliminating all competitors",
                "By allowing competitors to share resources for mutual benefit",
                "By avoiding market competition altogether",
                "By increasing rivalry between competitors"
            ],
            answer: "By allowing competitors to share resources for mutual benefit"
        },
        {
            question: "Which driver of collaborative competition involves policies created by governments?",
            options: [
                "Technology",
                "Political and legal issues",
                "Economic factors",
                "Market openness"
            ],
            answer: "Political and legal issues"
        },
        {
            question: "In the David & Goliath strategy, what does disrupting the status quo mean?",
            options: [
                "Following the rules set by larger competitors",
                "Challenging traditional ways of doing business",
                "Outspending competitors in marketing",
                "Copying successful competitors"
            ],
            answer: "Challenging traditional ways of doing business"
        },
        {
            question: "Which of the following is a limitation of collaborative competition?",
            options: [
                "It eliminates the need for innovation",
                "It reduces competitive rivalry completely",
                "It may lead to dependence on competitors",
                "It always increases production costs"
            ],
            answer: "It may lead to dependence on competitors"
        },
        {
            question: "How do startups benefit from using David’s strategy?",
            options: [
                "By competing head-to-head with larger firms",
                "By focusing on speed, precision, and disrupting norms",
                "By avoiding innovation and sticking to existing products",
                "By copying the strategies of larger companies"
            ],
            answer: "By focusing on speed, precision, and disrupting norms"
        },
        {
            question: "What is a key challenge of hyper-competition?",
            options: [
                "Lower production costs",
                "Easier market entry for new players",
                "Stronger interdependence between companies",
                "Difficulty in sustaining differentiation"
            ],
            answer: "Difficulty in sustaining differentiation"
        },
        {
            question: "What is the relationship between speed and agility in David’s strategy?",
            options: [
                "Speed allows quick adaptation; agility enables creative problem-solving",
                "Agility involves brute force, while speed involves precision",
                "Speed is only relevant in large markets",
                "Agility reduces the need for innovation"
            ],
            answer: "Speed allows quick adaptation; agility enables creative problem-solving"
        },
        {
            question: "Why is precision important in David’s strategy?",
            options: [
                "To increase production speed",
                "To target and exploit the weaknesses of larger competitors",
                "To reduce product costs",
                "To copy successful competitors"
            ],
            answer: "To target and exploit the weaknesses of larger competitors"
        },
        {
            question: "What are startups encouraged to do in hyper-competitive environments?",
            options: [
                "Focus on product life cycles only",
                "Adopt rigid long-term strategies",
                "Constantly innovate and adapt",
                "Avoid entering new markets"
            ],
            answer: "Constantly innovate and adapt"
        },
        {
            question: "How does technology drive collaborative competition?",
            options: [
                "By reducing the need for partnerships",
                "By enabling shared platforms and networks",
                "By making competition unnecessary",
                "By increasing product costs"
            ],
            answer: "By enabling shared platforms and networks"
        },
        {
            question: "What does stronger interdependency mean in today’s competitive environment?",
            options: [
                "Companies must rely more on each other for mutual growth",
                "Companies should eliminate partnerships",
                "Companies operate in isolation",
                "Competition becomes irrelevant"
            ],
            answer: "Companies must rely more on each other for mutual growth"
        }
    ]
};
const L4 = {
    key: 'L4',
    name: 'Lecture 4: Digital Transformation and Entrepreneurial Marketing',
    questions: [
        {
            question: "What are the three main barriers faced by startups in marketing?",
            options: [
                "Limited resources, lack of expertise, limited market impact",
                "High costs, regulatory issues, poor brand awareness",
                "Low customer demand, weak product quality, poor service",
                "High competition, lack of partnerships, low visibility"
            ],
            answer: "Limited resources, lack of expertise, limited market impact"
        },
        {
            question: "Which marketing strategy involves entrepreneurs selling directly to friends and contacts?",
            options: [
                "Inbound marketing",
                "Responsive marketing",
                "DIY marketing",
                "Collaborative marketing"
            ],
            answer: "DIY marketing"
        },
        {
            question: "How does digital transformation help startups in marketing?",
            options: [
                "By increasing offline marketing reach",
                "By providing tools that improve targeting and personalization",
                "By eliminating the need for customer data",
                "By reducing product development time"
            ],
            answer: "By providing tools that improve targeting and personalization"
        },
        {
            question: "What does Marketing 5.0 focus on?",
            options: [
                "Offline marketing strategies",
                "AI, technology, and human collaboration",
                "Reducing advertising costs",
                "Traditional advertising methods"
            ],
            answer: "AI, technology, and human collaboration"
        },
        {
            question: "How can partnerships reduce marketing costs for startups?",
            options: [
                "By enabling shared resources and customer bases",
                "By reducing the need for social media presence",
                "By eliminating advertising expenses entirely",
                "By avoiding customer engagement"
            ],
            answer: "By enabling shared resources and customer bases"
        },
        {
            question: "Why is responsive marketing important for startups?",
            options: [
                "It helps them focus on long-term goals only",
                "It allows them to quickly react to market changes and customer needs",
                "It reduces the need for innovation",
                "It eliminates competition"
            ],
            answer: "It allows them to quickly react to market changes and customer needs"
        },
        {
            question: "What is a key advantage of DIY marketing?",
            options: [
                "It requires no effort from the entrepreneur",
                "It is cost-effective and flexible",
                "It guarantees instant success",
                "It eliminates the need for partnerships"
            ],
            answer: "It is cost-effective and flexible"
        },
        {
            question: "What is one disadvantage of DIY marketing?",
            options: [
                "It limits customization",
                "It requires specialized expertise",
                "It may lead to errors due to lack of experience",
                "It reduces customer engagement"
            ],
            answer: "It may lead to errors due to lack of experience"
        },
        {
            question: "How do partnerships with influencers help startups?",
            options: [
                "By avoiding digital marketing",
                "By expanding reach to new customer segments",
                "By increasing product costs",
                "By focusing only on offline strategies"
            ],
            answer: "By expanding reach to new customer segments"
        },
        {
            question: "What role does AI play in Marketing 5.0?",
            options: [
                "It reduces the need for customer interaction",
                "It helps create personalized and data-driven campaigns",
                "It eliminates the need for marketing teams",
                "It focuses only on offline engagement"
            ],
            answer: "It helps create personalized and data-driven campaigns"
        },
        {
            question: "Why is word-of-mouth important for startups in the early stages?",
            options: [
                "Because it reduces product prices",
                "Because startups have limited marketing budgets",
                "Because it guarantees immediate sales",
                "Because customers avoid online platforms"
            ],
            answer: "Because startups have limited marketing budgets"
        },
        {
            question: "Which strategy focuses on adapting to customer feedback and market needs?",
            options: [
                "Inbound marketing",
                "Responsive marketing",
                "Outbound marketing",
                "Traditional marketing"
            ],
            answer: "Responsive marketing"
        },
        {
            question: "What is a major focus of inbound marketing?",
            options: [
                "Pushing ads to a large audience",
                "Creating valuable content to attract customers",
                "Reducing social media presence",
                "Avoiding partnerships"
            ],
            answer: "Creating valuable content to attract customers"
        },
        {
            question: "How can startups benefit from using digital tools in their marketing efforts?",
            options: [
                "By increasing efficiency and targeting specific customer segments",
                "By reducing the need for a product strategy",
                "By avoiding direct customer engagement",
                "By lowering product quality"
            ],
            answer: "By increasing efficiency and targeting specific customer segments"
        },
        {
            question: "Why is co-creation valuable for startups?",
            options: [
                "It eliminates the need for product testing",
                "It involves customers in product development, increasing relevance",
                "It focuses on reducing prices only",
                "It guarantees immediate market success"
            ],
            answer: "It involves customers in product development, increasing relevance"
        }
    ]
};

const L8 = {
    key: 'L8',
    name: 'Lecture 8: Content Marketing',
    questions: [
        {
            question: "What is the primary goal of content marketing?",
            options: [
                "To directly promote a product",
                "To attract, engage, and retain an audience",
                "To reduce advertising expenses",
                "To replace traditional marketing"
            ],
            answer: "To attract, engage, and retain an audience"
        },
        {
            question: "Which of the following is a type of content marketing?",
            options: [
                "Blog posts",
                "Television commercials",
                "Cold calling",
                "Billboards"
            ],
            answer: "Blog posts"
        },
        {
            question: "What are the main business goals achieved through content marketing?",
            options: [
                "Increasing product prices and brand awareness",
                "Expanding customer base, increasing sales, and brand credibility",
                "Reducing product costs and creating viral content",
                "Minimizing customer engagement"
            ],
            answer: "Expanding customer base, increasing sales, and brand credibility"
        },
        {
            question: "Why is inbound marketing closely related to content marketing?",
            options: [
                "Because it relies on interrupting customers with ads",
                "Because it focuses on creating valuable content that attracts customers",
                "Because it eliminates the need for customer engagement",
                "Because it avoids online platforms"
            ],
            answer: "Because it focuses on creating valuable content that attracts customers"
        },
        {
            question: "Which of the following is an example of inbound marketing?",
            options: [
                "Cold emails",
                "Creating educational blog content",
                "Television ads",
                "Billboard promotions"
            ],
            answer: "Creating educational blog content"
        },
        {
            question: "What are the 3Es of exceptional content marketing?",
            options: [
                "Entertain, Educate, Engage",
                "Excite, Explore, Expand",
                "Enhance, Evolve, Elevate",
                "Establish, Evaluate, Execute"
            ],
            answer: "Entertain, Educate, Engage"
        },
        {
            question: "How can consistency in content marketing benefit a brand?",
            options: [
                "By reducing production costs",
                "By building trust and brand recognition",
                "By avoiding competition",
                "By increasing product variety"
            ],
            answer: "By building trust and brand recognition"
        },
        {
            question: "Which of the following platforms is commonly used for content marketing?",
            options: [
                "Radio advertisements",
                "Social media platforms",
                "Telemarketing services",
                "Direct mail"
            ],
            answer: "Social media platforms"
        },
        {
            question: "What is a key benefit of video content in content marketing?",
            options: [
                "It reduces marketing costs significantly",
                "It is highly engaging and shareable",
                "It replaces all other forms of content",
                "It is less time-consuming to create"
            ],
            answer: "It is highly engaging and shareable"
        },
        {
            question: "Which company is known for pioneering inbound marketing?",
            options: [
                "HubSpot",
                "Apple",
                "Microsoft",
                "Coca-Cola"
            ],
            answer: "HubSpot"
        },
        {
            question: "Why is tailoring content to the target audience important?",
            options: [
                "To reduce content creation efforts",
                "To ensure the content resonates and adds value to the audience",
                "To lower marketing expenses",
                "To minimize engagement"
            ],
            answer: "To ensure the content resonates and adds value to the audience"
        },
        {
            question: "What is the difference between inbound and outbound marketing?",
            options: [
                "Inbound marketing pushes ads to the audience, while outbound attracts customers",
                "Inbound marketing attracts customers through content, while outbound pushes messages to them",
                "Inbound marketing is always free, while outbound is paid",
                "Inbound marketing eliminates customer interaction"
            ],
            answer: "Inbound marketing attracts customers through content, while outbound pushes messages to them"
        },
        {
            question: "Which metric is important for measuring the success of content marketing?",
            options: [
                "Number of cold calls",
                "Customer engagement and lead generation",
                "Offline advertisement views",
                "Total number of salespeople"
            ],
            answer: "Customer engagement and lead generation"
        },
        {
            question: "How can startups convert inbound marketing into sales?",
            options: [
                "By focusing solely on content creation",
                "By guiding customers through the sales process after engagement",
                "By eliminating all other forms of marketing",
                "By reducing customer interaction"
            ],
            answer: "By guiding customers through the sales process after engagement"
        },
        {
            question: "What is the first step in creating a content marketing strategy?",
            options: [
                "Setting content marketing objectives",
                "Choosing content formats",
                "Defining distribution channels",
                "Hiring a marketing team"
            ],
            answer: "Setting content marketing objectives"
        }
    ]
};

const L9 = {
    key: 'L9',
    name: 'Lecture 9: Converging Innovation and Improvement',
    questions: [
        {
            question: "What is the primary distinction between innovation and improvement?",
            options: [
                "Innovation creates new possibilities, while improvement enhances existing ones",
                "Improvement is more disruptive than innovation",
                "Innovation is only about technology, while improvement covers all areas",
                "Improvement always leads to higher risk than innovation"
            ],
            answer: "Innovation creates new possibilities, while improvement enhances existing ones"
        },
        {
            question: "What is a viable innovation?",
            options: [
                "An idea that only focuses on creativity",
                "An innovation that can be practically implemented and sustained",
                "A product that has been improved without customer feedback",
                "An expensive solution with limited market impact"
            ],
            answer: "An innovation that can be practically implemented and sustained"
        },
        {
            question: "What are the three key fits that startups must consider in innovation?",
            options: [
                "Problem-solution fit, product-market fit, get-give fit",
                "Customer fit, competitor fit, brand fit",
                "Price fit, product fit, promotion fit",
                "Revenue fit, profit fit, cost fit"
            ],
            answer: "Problem-solution fit, product-market fit, get-give fit"
        },
        {
            question: "Which fit ensures that customers are willing to pay for a product?",
            options: [
                "Problem-solution fit",
                "Product-market fit",
                "Get-give fit",
                "Revenue-cost fit"
            ],
            answer: "Get-give fit"
        },
        {
            question: "What does the 4C Analysis framework focus on?",
            options: [
                "Customer, Competitor, Company, Cost",
                "Core competencies, Competition, Customer loyalty, Costs",
                "Culture, Channels, Communication, Cash flow",
                "Capabilities, Collaboration, Campaigns, Conversion"
            ],
            answer: "Customer, Competitor, Company, Cost"
        },
        {
            question: "Why is customer-centric innovation important?",
            options: [
                "It focuses solely on reducing costs",
                "It ensures the product directly addresses real customer needs",
                "It eliminates the need for market research",
                "It reduces the complexity of product development"
            ],
            answer: "It ensures the product directly addresses real customer needs"
        },
        {
            question: "How can startups achieve product-market fit?",
            options: [
                "By offering a wide range of unrelated products",
                "By ensuring their product meets the needs of a defined market",
                "By focusing only on internal company goals",
                "By ignoring competitor actions"
            ],
            answer: "By ensuring their product meets the needs of a defined market"
        },
        {
            question: "What is a potential risk of focusing too heavily on innovation?",
            options: [
                "It reduces customer satisfaction",
                "It may lead to unsustainable R&D spending",
                "It guarantees immediate success",
                "It eliminates the need for improvement"
            ],
            answer: "It may lead to unsustainable R&D spending"
        },
        {
            question: "What is an example of market-driving innovation?",
            options: [
                "A company improving an existing product based on customer feedback",
                "A company introducing a product that creates a new market category",
                "A company reducing its product prices",
                "A company following existing market trends"
            ],
            answer: "A company introducing a product that creates a new market category"
        },
        {
            question: "How does competitor analysis in 4C Analysis help startups?",
            options: [
                "By ensuring startups copy competitor strategies",
                "By helping startups identify gaps and differentiate their product",
                "By focusing only on reducing product costs",
                "By avoiding direct competition"
            ],
            answer: "By helping startups identify gaps and differentiate their product"
        },
        {
            question: "Why is cost analysis crucial in innovation?",
            options: [
                "To increase R&D spending beyond sustainable levels",
                "To ensure the product can be profitably developed and sold",
                "To eliminate unnecessary market research",
                "To guarantee instant profitability"
            ],
            answer: "To ensure the product can be profitably developed and sold"
        },
        {
            question: "What role do core competencies play in innovation?",
            options: [
                "They ensure innovation aligns with the company's strengths",
                "They eliminate the need for partnerships",
                "They guarantee market success without research",
                "They reduce the cost of product development"
            ],
            answer: "They ensure innovation aligns with the company's strengths"
        },
        {
            question: "How does the relationship between R&D and revenue impact innovation?",
            options: [
                "High R&D spending always guarantees higher revenue",
                "R&D spending must be balanced with revenue generation to avoid financial strain",
                "Revenue is irrelevant to innovation efforts",
                "R&D should only focus on short-term gains"
            ],
            answer: "R&D spending must be balanced with revenue generation to avoid financial strain"
        },
        {
            question: "Which of the following is an example of product-market fit?",
            options: [
                "Launching a product with no defined target market",
                "Offering a product that meets a specific market demand",
                "Introducing a product without competitor analysis",
                "Focusing only on cost reduction"
            ],
            answer: "Offering a product that meets a specific market demand"
        },
        {
            question: "What is the ultimate goal of viable innovations?",
            options: [
                "To maximize short-term profits only",
                "To create sustainable business growth and competitive advantage",
                "To reduce costs to zero",
                "To eliminate all competitors"
            ],
            answer: "To create sustainable business growth and competitive advantage"
        }
    ]
};

const L10 = {
    key: 'L10',
    name: 'Lecture 10: Branding and Positioning',
    questions: [
        {
            question: "What is the primary purpose of branding?",
            options: [
                "To create a unique identity and build trust with customers",
                "To increase product prices",
                "To eliminate competitors",
                "To reduce production costs"
            ],
            answer: "To create a unique identity and build trust with customers"
        },
        {
            question: "What does brand equity refer to?",
            options: [
                "The added value a brand gives to a product",
                "The cost of creating a logo",
                "The number of customers a brand has",
                "The company’s net profit"
            ],
            answer: "The added value a brand gives to a product"
        },
        {
            question: "How does a strong brand equity affect customer behavior?",
            options: [
                "Customers are willing to pay a premium for the product",
                "Customers demand lower prices",
                "Customers avoid the brand",
                "Customers lose trust in the brand"
            ],
            answer: "Customers are willing to pay a premium for the product"
        },
        {
            question: "What is brand positioning?",
            options: [
                "Establishing a unique place for a brand in customers' minds",
                "The process of designing a logo",
                "Lowering product prices",
                "Increasing product variety"
            ],
            answer: "Establishing a unique place for a brand in customers' minds"
        },
        {
            question: "What is a brand positioning map?",
            options: [
                "A visual tool that shows how a brand compares to competitors",
                "A strategy to reduce competition",
                "A pricing model for branded products",
                "A guide to creating advertisements"
            ],
            answer: "A visual tool that shows how a brand compares to competitors"
        },
        {
            question: "How can a company use a brand facelift?",
            options: [
                "By updating its visual identity to stay relevant",
                "By reducing product prices",
                "By eliminating old customers",
                "By avoiding competition"
            ],
            answer: "By updating its visual identity to stay relevant"
        },
        {
            question: "What is the difference between a slogan and a tagline?",
            options: [
                "A slogan is campaign-specific, while a tagline reflects the brand’s core identity",
                "A slogan is permanent, while a tagline changes frequently",
                "A slogan focuses on pricing, while a tagline focuses on quality",
                "A slogan is longer than a tagline"
            ],
            answer: "A slogan is campaign-specific, while a tagline reflects the brand’s core identity"
        },
        {
            question: "Why is consistency important in branding?",
            options: [
                "It helps build trust and reinforces brand identity",
                "It reduces marketing expenses",
                "It guarantees immediate sales",
                "It avoids market competition"
            ],
            answer: "It helps build trust and reinforces brand identity"
        },
        {
            question: "What is the final stage in the Keller Brand Equity Model?",
            options: [
                "Brand resonance",
                "Brand meaning",
                "Brand identity",
                "Brand response"
            ],
            answer: "Brand resonance"
        },
        {
            question: "What does brand resonance mean?",
            options: [
                "A deep connection where customers feel loyal and become advocates",
                "A temporary increase in brand popularity",
                "The process of rebranding a product",
                "The cost of acquiring new customers"
            ],
            answer: "A deep connection where customers feel loyal and become advocates"
        },
        {
            question: "How do emblems contribute to a brand's identity?",
            options: [
                "They create a traditional and official look for the brand",
                "They eliminate the need for logos",
                "They replace product names",
                "They reduce marketing costs"
            ],
            answer: "They create a traditional and official look for the brand"
        },
        {
            question: "When should a company consider using a mascot?",
            options: [
                "When it wants to create a friendly and relatable image",
                "When it wants to reduce product prices",
                "When it wants to target only corporate clients",
                "When it focuses solely on offline marketing"
            ],
            answer: "When it wants to create a friendly and relatable image"
        },
        {
            question: "What is Keller's definition of brand identity?",
            options: [
                "How customers perceive the brand",
                "The brand's unique and consistent image",
                "The price range of the brand's products",
                "The company’s annual revenue"
            ],
            answer: "The brand's unique and consistent image"
        },
        {
            question: "What role do core values play in branding?",
            options: [
                "They guide the brand’s actions and messaging",
                "They reduce marketing costs",
                "They increase product variety",
                "They guarantee immediate sales"
            ],
            answer: "They guide the brand’s actions and messaging"
        },
        {
            question: "Why is brand equity important for long-term business success?",
            options: [
                "It builds customer loyalty and allows for premium pricing",
                "It reduces production costs",
                "It guarantees instant market dominance",
                "It eliminates the need for product innovation"
            ],
            answer: "It builds customer loyalty and allows for premium pricing"
        }
    ]
};


const chapters = [
    L3,
    L4,
    L8,
    L9,
    L10
];

export default chapters;